// Constants
const rentalHamburger = document.getElementById('hamburger');
const rentalNav = document.querySelector('.header__navigation');
const rentalNavigation = document.getElementById('navigation');
const rentalNavLinks = document.querySelectorAll('.header--navigation ul li a');
const rentalSections = document.querySelectorAll('.section');
const rentalAccordions = document.querySelectorAll('.accordion');
const rentalAccordionButtons = document.querySelectorAll('.accordion__button');
const rentalShowMoreButton = document.getElementById('show-more');
const rentalPricesButtonMonthly = document.querySelector('.prices__button--monthly');
const rentalPricesButtonYearly = document.querySelector('.prices__button--yearly');
const rentalPricesTableMonthly = document.querySelector('.prices__table--monthly');
const rentalPricesTableYearly = document.querySelector('.prices__table--yearly');

// Hamburger
function hamburgerToggle(): void {
    rentalHamburger?.addEventListener('click', () => {
        rentalHamburger?.classList.toggle('open');
        rentalNavigation?.classList.toggle('open');
    });
}

// Accordion
function accordionsClick(): void {
    rentalAccordionButtons.forEach((button) => {
        button.addEventListener('click', () => {
            const accordionPanel = button.nextElementSibling;

            button.classList.toggle('active');

            if (button.classList.contains('active')) {
                accordionPanel?.classList.toggle('show');
            } else {
                accordionPanel?.classList.remove('show');
            }
        });
    });
}

// Show more - remove hidden class from all accordions and remove show more button
function showMore(): void {
    rentalShowMoreButton?.addEventListener('click', () => {
        rentalAccordions.forEach((accordion) => {
            if (accordion.classList.contains('hidden')) {
                accordion.classList.remove('hidden');
            }
        });
        rentalShowMoreButton.remove();
    });
}

// Active state navigation
function activeNavigation(): void {
    function scrollActive(): void {
        const scrollY = window.scrollY || window.pageYOffset;

        rentalSections.forEach((section) => {
            if (section instanceof HTMLElement) {
                const sectionHeight = section.offsetHeight;
                const sectionTop = section.offsetTop - 150;
                const sectionId = section.getAttribute('id');
                const anchor = document.querySelector(
                    `.header--navigation ul li a[href*="${sectionId}"]`,
                );

                if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
                    anchor?.classList.add('active');
                } else {
                    anchor?.classList.remove('active');
                }
            }
        });

        // Highlight second last nav item when scrolled to the bottom
        const secondLast = rentalNavLinks[rentalNavLinks.length - 2];

        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
            rentalNavLinks.forEach((navItem) => {
                navItem.classList.remove('active');
            });
            secondLast?.classList.add('active');
        }
    }

    window.addEventListener('scroll', scrollActive);
}

// Close navigation when clicked on ul li a on tablet and mobile
function closeNavigationOnMobile(): void {
    if (window.innerWidth < 992) {
        rentalNavLinks.forEach((navItem) => {
            navItem.addEventListener('click', () => {
                rentalNav?.classList.remove('open');
                rentalHamburger?.classList.remove('open');
            });
        });
    }
}

// Prices toggle
function pricesToggle(): void {
  rentalPricesButtonMonthly?.addEventListener('click', () => {
      rentalPricesButtonMonthly.classList.add('active');
      rentalPricesButtonYearly?.classList.remove('active');
      rentalPricesTableMonthly?.classList.add('active');
      rentalPricesTableYearly?.classList.remove('active');
  });

  rentalPricesButtonYearly?.addEventListener('click', () => {
      rentalPricesButtonMonthly?.classList.remove('active');
      rentalPricesButtonYearly.classList.add('active');
      rentalPricesTableMonthly?.classList.remove('active');
      rentalPricesTableYearly?.classList.add('active');
  });
}

document.addEventListener('DOMContentLoaded', () => {
    hamburgerToggle();
    activeNavigation();
    closeNavigationOnMobile();
    accordionsClick();
    showMore();
    pricesToggle();
});
